import React, { useEffect, useState } from 'react';
import UserAPI from './UserAPI';
import InfoAPI from './InfoAPI';
import { formatTitleToDB, formatTitleToDisplay } from '../utils/Functions';
import { refreshTime } from '../utils/DisplaySettings';

const Leaderboard = () => {
    const [leaderBoard, setLeaderBoard] = useState([]);
    
  const [lastSongName, setLastSongName] = useState(null);
  const [lastArtist, setLastArtist] = useState(null);

    const loadLeaderBoard = async () => {
        const idToken = localStorage.getItem('idToken');
        const payloadBase64 = idToken.split('.')[1];
        const decodedPayload = JSON.parse(atob(payloadBase64.replace(/-/g, '+').replace(/_/g, '/')));
        const session_name = decodedPayload['cognito:username'];
        const requestBody = JSON.stringify({
            'request_type': 'info',
            'session_name': session_name,
            'info_type': 'leaderboard'
        });
        const jsonData = await InfoAPI(requestBody);
        if (jsonData) {
            const result = JSON.parse(jsonData.body);
            const newLeaderBoard = result.map(element => ({
                user: element[0],
                points: element[1],
                request: element[2] && element[3] 
                    ? formatTitleToDisplay(element[3]) + ' by ' + formatTitleToDisplay(element[2])
                    : ''
            }));
            setLeaderBoard(newLeaderBoard);
        }
    };

    useEffect(() => {
        console.log('Setting up leaderboard interval');
        let mounted = true;
        let intervalId = null;

        const loadData = async () => {
            if (mounted) {
                await loadLeaderBoard();
                await getSongPlayed();
            }
        };

        // Initial load
        loadData();

        // Set up interval
        intervalId = setInterval(() => {
            loadData();
        }, refreshTime);

        // Cleanup function
        return () => {
            console.log('Cleaning up leaderboard interval');
            mounted = false;
            if (intervalId) {
                clearInterval(intervalId);
                console.log('Interval cleared');
            }
        };
    }, []); // Empty dependency array since we don't need any dependencies

    const resetLeaderBoard = async () => {
        console.log('Resetting leaderboard');
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': localStorage.getItem('idToken'),
            'user_request_type': 'event',
            'event_type': 'startLeaderBoard'
        });
        await UserAPI(requestBody);
        await loadLeaderBoard();
    };

    const getSongPlayed = async () => {
        const requestBody = JSON.stringify({
        'request_type': 'info',
          'session_name': JSON.parse(atob(localStorage.getItem('idToken').split('.')[1].replace(/-/g, '+').replace(/_/g, '/')))['cognito:username'],
          'info_type': 'songplayed'
        });
        const jsonData = await InfoAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        
        var song= result.song_name;
        var artist= result.artist_name;
        if (song && artist) {
          song= formatTitleToDisplay(song);
          artist=formatTitleToDisplay(artist);
          setLastSongName(song);
          setLastArtist(artist);
        }
      };

    const songGrant = async (index) => {
        console.log('Granting song to user:', leaderBoard[index].user);
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': localStorage.getItem('idToken'),
            'user_request_type': 'event',
            'event_type': 'takesongrequest',
            'user_name': leaderBoard[index].user
        });
        await UserAPI(requestBody);
        await loadLeaderBoard();
    };

    return (
        <div style={{ width: '100rem' }}>

            <div>
              {lastSongName !== null && (
                <p>Playing: {lastSongName + ' by ' + lastArtist}</p>
              )}
            </div>

            
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={resetLeaderBoard}>Reset leader board</button>
                </div>
                <div style={{ margin: '1.25rem 0' }}></div>
                <div style={{ marginLeft: '1.25rem', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '0.625rem' }}>
                    <div style={{ flex: '1 1 25%' }}>Viewer</div>
                    <div style={{ flex: '1 1 25%' }}>Points</div>
                    <div style={{ flex: '1 1 25%' }}>Request</div>
                    <div style={{ flex: '1 1 25%' }}></div>
                </div>
                <hr />
                {leaderBoard.length > 0 && 
                leaderBoard.map((user, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.625rem', marginBottom: '0.625rem' }}>
                        <div style={{ flex: '1 1 25%' }}>{user.user}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.points}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.request}</div>
                        <button onClick={() => songGrant(index)} style={{ flex: '1 1 25%' }}>Grant</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Leaderboard;
