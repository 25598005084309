import React from 'react';

const Tutorial = () => {
    return (
        <div>
            <h1>Tutorial</h1>
            <p>Open a session to welcome your viewers, which will be able to access through the "attend" interface.</p>
            <p>Open the settings menu to change the parameters of the session (video streaming URL, timings and points).</p>
            <p>Open the song list menu to manage your playlist.</p>
            <p>Copy overlay URL to insert it in your video streaming page, or OBS.</p>
            <p>After your session is open, launch the scan to:</p>
            <p>1. Collect live emotions from your viewers' webcam. The emotions are collected every 3 seconds. The count is displayed in this interface as well as on the overlay.</p>
            <p>2. Collect impressions from your viewers, which are entered manually with cursors.</p>
            <p>Viewers with the most points appear on the leaderboard.</p>
            <p><strong>Good luck and have fun!</strong></p>

        </div>
    );
};

export default Tutorial;
