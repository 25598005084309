import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthContext';
import UserAPI from '../components/UserAPI';
import styled from 'styled-components';
import TuneCoinImage from '../images/tunecoin.png';
import { padding, marginNumber, navigationWidthNumber, buttonPaddingNumber, secondaryColor, secMenuWidthNumber, backgroundColor, secondaryBackgroundColor } from '../utils/DisplaySettings';
import userProfileImage from '../images/userprofile.png';
import isMobileFunc from '../utils/DisplaySettings';

const buttonWidth = '20rem'
const inputWidth = '60rem'

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${secondaryBackgroundColor};
  border: 0.125rem solid ${secondaryColor};
  border-radius: 0.9375rem;
  box-shadow: 0 0 0.9375rem ${secondaryColor};
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;
const Profile = () => {
    const [YT_channel_URL, setYT_channel_URL] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const isMobile = isMobileFunc();
    const [credits, setCredits] = useState(null);
    

    const getCredits = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;

        try {
            const bodyData = JSON.stringify({
                'request_type': 'user',
                'idToken': storedToken,
                'user_request_type': 'getcredits'
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData) {  // Check if component is still mounted
                const result = JSON.parse(jsonData.body);
                if (result && result.credits !== undefined) {  // Validate credits exist
                    setCredits(result.credits);
                }
            }
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    };

    const getSettings = async () => {
        const idToken = localStorage.getItem('idToken');
        try {
            if (!idToken) {
                console.error('No token available');
                return;
            }

            console.log('Getting settings');
            const jsonData = await UserAPI(JSON.stringify({ 
                'request_type': 'user', 
                'idToken': idToken,
                'user_request_type': 'getsettings' 
            }));
            
            if (!jsonData) return;
            
            const result = JSON.parse(jsonData.body);
            console.log(result);
            setYT_channel_URL(result.YT_channel_URL);
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    

    useEffect(() => {
        console.log('Profile component mounted');
        getSettings();
        getCredits();
        return () => {
            console.log('Profile component unmounted');
        };
    }, []);

    const setYoutubeChannel = async () => {
        console.log('setYoutubeChannel called');
        const idToken = localStorage.getItem('idToken');
        if (!idToken) {
            console.error('No token available');
            return;
        }

        const youtubeChannel = document.getElementById('embedURLinput')?.value || '';
        
        // Add URL validation
        const youtubeChannelRegex = /^https:\/\/www\.youtube\.com\/@[\w-]+$/;
        if (!youtubeChannelRegex.test(youtubeChannel)) {
            alert('Please enter a valid YouTube channel URL in the format: https://www.youtube.com/@CHANNELNAME');
            return;
        }

        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'changesetting',
            'setting': 'YT_channel_URL',
            'value': youtubeChannel
        });
        
        try {
            const response = await UserAPI(requestBody);
            setYT_channel_URL(youtubeChannel);
            if (response) {
                console.log('Youtube channel updated successfully');
                setStatusMessage('Channel URL updated successfully!');
            }
        } catch (error) {
            console.error('Error updating Youtube channel:', error);
            setStatusMessage('Error updating channel URL');
        }
    };

    useEffect(() => {
        if (statusMessage) {
            const timer = setTimeout(() => {
                setStatusMessage('');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [statusMessage]);

    return (
        <div style={{
            marginTop: isMobile ? '6rem' : '5vh',
            marginLeft: isMobile ? '0' : `${navigationWidthNumber + 2}rem`,
            width: isMobile ? '100%' : `calc(100% - ${navigationWidthNumber + 2}rem)`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0 20px',
            boxSizing: 'border-box'
        }}>
            <div style={{ textAlign: 'center', width: '100%' }}>
                <img 
                    src={userProfileImage} 
                    alt="User Profile"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
            </div>
            <ContentContainer>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <h2 style={{ marginRight: '1rem' }}>Credits: {credits}</h2>
                        <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '2rem', height: '2rem' }} />
                        <h2>(1</h2>
                        <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '2rem', height: '2rem' }} />
                        <h2>=0.01$)</h2>
                        
                        <input
                            type="number"
                            min="1"
                            placeholder="Amount"
                            style={{
                                width: '5rem',
                                padding: '0.5rem',
                                marginRight: '0.5rem'
                            }}
                            id="creditsInput"
                        />
                        <button onClick={() => {
                            console.log('Add credits clicked');
                            alert('Thank you for your interest! This feature will be available soon.');
                        }}>
                            Add
                        </button>
                    </div>
                </div>
            </ContentContainer>
            <ContentContainer>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', width: '100%' }}>
                    <input 
                        id="embedURLinput" 
                        type="text" 
                        style={{ width: inputWidth }}
                        placeholder={`Enter ${YT_channel_URL ? `(current: ${YT_channel_URL})` : 'Youtube channel URL - format https://www.youtube.com/@CHANNELNAME'}`}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', minHeight: '2.5rem' }}>
                        <button onClick={setYoutubeChannel}>
                            Set Youtube Channel URL
                        </button>
                        {statusMessage && (
                            <span style={{ 
                                color: 'white',
                                transition: 'opacity 0.3s ease-in-out'
                            }}>
                                {statusMessage}
                            </span>
                        )}
                    </div>
                </div>
            </ContentContainer>
        </div>
    );
};

export default Profile;