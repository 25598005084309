import React from 'react';
import { useParams } from 'react-router-dom';
import SessionUserUI from '../components/SessionUserUI';

const SessionUserPage = () => {
    const { sessionName } = useParams();
    
    return <SessionUserUI selectedSession={sessionName} />;
};

export default SessionUserPage; 