import styled from 'styled-components';
import { secondaryBackgroundColor, secondaryColor, navigationWidthNumber, marginNumber, buttonPaddingNumber, secMenuWidthNumber, primaryColor, secMenuUserWidthNumber } from './DisplaySettings';

// Add base font size calculation
const calculateRem = (pixels) => `${pixels / 16}rem`;  // 16px is the default browser font size

// Base Input - Common styles for all inputs
const BaseInput = styled.input`
  background: ${secondaryBackgroundColor};
  border: ${calculateRem(1)} solid ${secondaryColor};
  border-radius: ${calculateRem(4)};
  color: white;
  padding: ${calculateRem(8)};
  font-size: clamp(${calculateRem(16)}, 2vw, ${calculateRem(20)});
  transition: box-shadow 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: ${secondaryColor};
    box-shadow: 0 0 ${calculateRem(5)} ${secondaryColor};
  }
`;

// Text Input
const StyledTextInput = styled(BaseInput)`
  width: ${props => props.width || '15rem'};
  margin: ${props => props.margin || '0 0.5rem 0 0'};
`;

// Number Input
const StyledNumberInput = styled(BaseInput).attrs({ type: 'number' })`
  width: ${props => props.width || '5rem'};
  margin: ${props => props.margin || '0'};
  
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
    height: 1.5rem;
  }

  /* Firefox */
  -moz-appearance: textfield;
  &[type=number] {
    -moz-appearance: textfield;
  }
`;

// Search Input
const StyledSearchInput = styled(BaseInput).attrs({ type: 'search' })`
  width: ${props => props.width || '20rem'};
  margin: ${props => props.margin || '0'};
  padding-left: 2rem;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="%23028cd5" stroke-width="2"><circle cx="11" cy="11" r="8"/><line x1="21" y1="21" x2="16.65" y2="16.65"/></svg>');
  background-repeat: no-repeat;
  background-position: 0.5rem center;
`;

const ButtonColumn = styled.div`
  position: fixed;
  left: ${calculateRem(navigationWidthNumber * 16 + marginNumber * 16 + buttonPaddingNumber * 16)};
  top: ${calculateRem(20)};
  display: flex;
  flex-direction: column;
  gap: ${calculateRem(10)};
  width: ${calculateRem(secMenuWidthNumber * 16)};
  button {
    width: 100%;
  }
`;


const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${secondaryBackgroundColor};
  border: ${calculateRem(2)} solid ${secondaryColor};
  border-radius: ${calculateRem(15)};
  box-shadow: 0 0 ${calculateRem(15)} ${secondaryColor};
  padding: ${calculateRem(20)};
  margin-top: ${calculateRem(20)};
  margin-bottom: ${calculateRem(20)};
`;

// Button styling with pixel-based calculations
const StyledButton = styled.button`
  background-color: var(--background-color);
  color: var(--text-color);
  border: ${calculateRem(2)} solid ${primaryColor};
  padding: clamp(${calculateRem(8)}, 2vw, ${calculateRem(16)}) clamp(${calculateRem(16)}, 3vw, ${calculateRem(32)});
  font-size: clamp(${calculateRem(16)}, 2vw, ${calculateRem(20)});
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 ${calculateRem(10)} ${primaryColor};
  margin: ${calculateRem(8)} clamp(${calculateRem(8)}, 2vw, ${calculateRem(16)});

  &:hover {
    background-color: ${primaryColor};
    color: white;
    box-shadow: 0 0 ${calculateRem(10)} ${primaryColor};
  }
`;

const SecColumn = styled.div`
  position: fixed;
  left: ${navigationWidthNumber+marginNumber+buttonPaddingNumber}rem;
  top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: ${secMenuUserWidthNumber}rem;
  button {
    width: 100%;
  }
`;


const ContentRow = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
`;

const HalfContentContainer = styled(ContentContainer)`
  width: 50%;
  margin: 1.25rem 0;
`;

const TopSecRow = styled.div`
  position: fixed;
  top: 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  width: 100%;
  padding: 0 1.25rem;
  z-index: 1;
`;

const SecRow = styled.div`
  position: fixed;
  top: 5rem;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  width: 100%;
  padding: 0 1.25rem;
  z-index: 1;
`;


export const Title = styled.h1`
  font-size: clamp(1.5rem, 5vw, 3rem);
  margin-bottom: 1rem;
`;

export const Subtitle = styled.h2`
  font-size: clamp(1.25rem, 4vw, 2.5rem);
  margin-bottom: 0.875rem;
`;

export const Text = styled.p`
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.5;
  margin-bottom: 1rem;
`;

export const SmallText = styled.span`
  font-size: clamp(0.875rem, 1.5vw, 1rem);
`; 

const SmallButton = styled.button`
  background-color: black;
  color: white;
  border: 1px solid white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;
  box-shadow: none;  /* Override global styles */
  margin: 0;  /* Override global button margin */

  &:hover {
    background-color: ${secondaryColor};
    box-shadow: none;  /* Ensure no shadow on hover */
  }
`;


export { SmallButton, ButtonColumn, ContentContainer, StyledTextInput, StyledNumberInput, StyledSearchInput, StyledButton, SecColumn, ContentRow, HalfContentContainer, TopSecRow, SecRow };
