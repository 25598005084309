import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthContext';
import UserAPI from '../components/UserAPI';
import EmotionScanner from '../components/EmotionScanner';
import SongsMenu from '../components/SongsMenu';
import SettingsMenuCreate from '../components/SettingsMenuCreate';
import styled from 'styled-components';
import { padding, marginNumber, navigationWidthNumber, buttonPaddingNumber, secondaryColor, secMenuWidthNumber, backgroundColor, secondaryBackgroundColor } from '../utils/DisplaySettings';
import Leaderboard from '../components/Leaderboard';
import creatorConsoleImage from '../images/creatorConsole.png';
import isMobileFunc from '../utils/DisplaySettings';
import CreatorTutorial from '../components/CreatorTutorial';
import { ButtonColumn, ContentContainer } from '../utils/StyledComponents';


const Create = () => {
  const [sessionOnGoing, setSessionOnGoing] = useState(null);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [songsMenu, setSongsMenu] = useState(false);
  const isMobile = isMobileFunc();
  const [tutorial, setTutorial] = useState(false);

  useEffect(() => {
    let mounted = true;

    const getSessionStatus = async () => {
      const requestBody = JSON.stringify({
        'request_type': 'user',
        'idToken': localStorage.getItem('idToken'),
        'user_request_type': 'getsessionstatus'
      });
      const jsonData = await UserAPI(requestBody);
      if (mounted) {
        if (jsonData) {
        const result = JSON.parse(jsonData.body);
        setSessionOnGoing(result.session_ongoing ? true : false);
        }
      }
    };

    getSessionStatus();

    // Cleanup function
    return () => {
      mounted = false;
      setSessionOnGoing(null);
      setSettingsMenu(false);
      setSongsMenu(false);
    };
  }, []);

  const startstopSession = async () => {
    const requestBody = JSON.stringify({
      'request_type': 'user',
      'idToken': localStorage.getItem('idToken'),
      'user_request_type': 'startstopsession'
    });

    // Call API for starting or stopping a session
    const jsonData = await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);

    if (result.session_ongoing) {
      // start session
      setSessionOnGoing(true);
    } else {
      // stop session
      setSessionOnGoing(false);
    }
  };

  const openTutorial = async () => {
    setTutorial(!tutorial);
  };


  const openSettings = async () => {
    setSettingsMenu(!settingsMenu);
  };

  const openSongsMenu = async () => {
    setSongsMenu(!songsMenu);
  };

  const copyOverlayUrl = () => {
    const idToken = localStorage.getItem('idToken');
    const payloadBase64 = idToken.split('.')[1];
    const decodedPayload = JSON.parse(atob(payloadBase64.replace(/-/g, '+').replace(/_/g, '/')));
    const eventId = decodedPayload['cognito:username'];
    const overlayUrl = `${window.location.origin}/overlay/${eventId}?session_name=${eventId}`;
    navigator.clipboard.writeText(overlayUrl);
    alert('Overlay URL copied to clipboard!');
  };


  return (
    <div style={{
      marginTop: isMobile ? '6rem' : '5vh',
      marginLeft: isMobile ? '0' : `${navigationWidthNumber + secMenuWidthNumber + 4}rem`,
      width: isMobile ? '100%' : `calc(100% - ${navigationWidthNumber + secMenuWidthNumber + 6}rem)`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 20px',
      boxSizing: 'border-box'
    }}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <img 
          src={creatorConsoleImage}
          alt="Creator Console"
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
      </div>

      <div style={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
        <ButtonColumn>
          <button onClick={startstopSession} id="sessionButton">
            {sessionOnGoing ? 'Stop Session' : 'Start Session'}
          </button>
          <button onClick={copyOverlayUrl}>Copy overlay URL</button>
          <button onClick={openSettings}>{settingsMenu ? 'Close settings' : 'Open settings'}</button>
          <button onClick={openSongsMenu}>{songsMenu ? 'Close song list' : 'Open song list'}</button>
          <button onClick={openTutorial}>Open tutorial</button>
        </ButtonColumn>
        

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {tutorial && (
                            <ContentContainer>
            <CreatorTutorial/>
          </ContentContainer>
        )}
          {sessionOnGoing && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <ContentContainer>
                <EmotionScanner />
              </ContentContainer>
              
              <ContentContainer>
                <Leaderboard />
              </ContentContainer>
            </div>
          )}
          {settingsMenu && (
            <ContentContainer>
              <SettingsMenuCreate />
            </ContentContainer>
          )}
          {songsMenu && (
            <ContentContainer>
              <SongsMenu />
            </ContentContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
