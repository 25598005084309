import React, { useEffect, useState, useRef } from 'react';
import UserSongsMenu from './UserSongsMenu';
import UserAPI from './UserAPI';
import TuneCoinImage from '../images/tunecoin.png';
import InfoAPI from './InfoAPI';
import ImageAnalysisAPI from './ImageAnalysisAPI';
import Impressions from './Impressions';
import { refreshTime, scanFrequency } from '../utils/DisplaySettings';
import styled from 'styled-components';
import Tutorial from './Tutorial';
import { 
    navigationWidthNumber, 
    marginNumber, 
    buttonPaddingNumber, 
    secMenuWidthNumber, 
    secondaryColor, 
    padding, 
    secMenuUserWidthNumber, 
    backgroundColor, 
    secondaryBackgroundColor 
} from '../utils/DisplaySettings';
import EmbeddedStream from './EmbeddedStream';
import isMobileFunc from '../utils/DisplaySettings';
import { useNavigate } from 'react-router-dom';

import { getSessionNameFromUrl } from '../utils/Functions';

import * as faceapi from '@vladmandic/face-api';
import { useModelLoading, FACE_DETECTOR_OPTIONS, loadModels } from '../utils/FaceApiUtils.jsx';
import { ButtonColumn, ContentContainer, StyledTextInput, StyledNumberInput, StyledSearchInput, StyledButton } from '../utils/StyledComponents';
import { ContentRow, HalfContentContainer, SecColumn, TopSecRow,SecRow } from '../utils/StyledComponents';



const SessionUserUI = () => {
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);
    const intervalIdRef = useRef(null);
    const [scan, setScan] = useState(false);
    const [mounted, setMounted] = useState(true);
    const [sessionName, setSessionName] = useState(null);

    const [credits, setCredits] = useState(null);
    const [points, setPoints] = useState(null);
    const [lastSongName, setLastSongName] = useState(null);
    const [lastArtist, setLastArtist] = useState(null);
    const [embedding, setEmbedding] = useState(null);
    const [YT_channel_URL, setYT_channel_URL] = useState('none');
    const [tutorial, setTutorial] = useState(false);


    const [headVisible, setHeadVisible] =  useState(localStorage.getItem('head_visible') || 'false');
    const [currentEmotion, setCurrentEmotion] = useState(localStorage.getItem('emotion') || 'unknown');
    const [isNodding, setIsNodding] = useState(localStorage.getItem('is_nodding') === 'true');
    const [noddingFrequency, setNoddingFrequency] = useState(localStorage.getItem('nodding_frequency') || 0);
    const [noddingAmplitude, setNoddingAmplitude] = useState(localStorage.getItem('nodding_amplitude') || 0);

    const [timeDelay, setTimeDelay] = useState(null);
    const [timeInterval, setTimeInterval] = useState(null);
    const [pointsperwin, setPointsperwin] = useState(null);
    const [pointspersongrequestgrant, setPointspersongrequestgrant] = useState(null);


    const isMobile = isMobileFunc();

    const { isLoading, isError, isReady } = useModelLoading();
    const [detectorInitialized, setDetectorInitialized] = useState(false);
    const detectionLoopRef = useRef(null);
    const isRunningRef = useRef(false);
    

    const navigate = useNavigate();


    useEffect(() => {
        const sessionFromUrl = getSessionNameFromUrl();
        if (sessionFromUrl) {
            setSessionName(sessionFromUrl);
        } else {
            // Redirect to home if no session name in URL
            navigate('/');
        }
    }, [navigate]);

    const getMedia = async () => {
        console.log('Attempting to get media stream...');
        try {
            console.log('Checking media devices...');
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                throw new Error('Media Devices API not supported');
            }
            
            const mediaStream = await navigator.mediaDevices.getUserMedia({ 
                video: {
                    width: { ideal: 200 }
                }
            });
            console.log('Media stream obtained:', mediaStream);
            setStream(mediaStream);
            if (videoRef.current) {
                console.log('Video ref exists, setting srcObject');
                videoRef.current.srcObject = mediaStream;
            } else {
                console.log('Video ref does not exist yet');
            }
        } catch (err) {
            console.error('Media error:', err);
            if (err.name === 'NotAllowedError') {
                console.log('Camera permission denied');
                alert('Permission to access camera was denied. Please allow access to the camera and try again.');
            } else if (err.name === 'NotFoundError') {
                console.log('No camera found');
                alert('No camera device found. Please ensure a camera is connected and try again.');
            } else if (err.name === 'NotReadableError') {
                console.log('Camera in use');
                alert('Camera is already in use by another application. Please close other applications and try again.');
            } else {
                console.log('Other media error:', err.message);
                alert('Error accessing media devices: ' + err.message);
            }
        }
    };

    

    

    useEffect(() => {
        setMounted(true);
        getMedia();
        getSettings();
        const intervalId = setInterval(refreshTime);

        return () => {
            setMounted(false);
            clearInterval(intervalId);
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        const checkEmotionStatus = () => {
            setHeadVisible(localStorage.getItem('head_visible') || 'false');
            setCurrentEmotion(localStorage.getItem('emotion') || 'unknown');
            setIsNodding(localStorage.getItem('is_nodding') === 'true');
            setNoddingFrequency(localStorage.getItem('nodding_frequency') || 0);
            setNoddingAmplitude(localStorage.getItem('nodding_amplitude') || 0);
        };

        const emotionInterval = setInterval(checkEmotionStatus, 250); // Check every 100ms

        return () => clearInterval(emotionInterval);
    }, []);

    //////////////////////////////////// CLOUD EMOTION DETECTION ////////////////////////////////////

    const handleCapture = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) {
            console.error('No token available');
            return;
        }

        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        const refVideoHeight = 200;
        context.drawImage(video, 0, 0, refVideoHeight * canvas.width / canvas.height, refVideoHeight);
        
        try {
            // Convert canvas to base64 string
            const base64Image = canvas.toDataURL('image/jpeg').split(',')[1];
            
            var requestBody = {
                "image": base64Image,  // Send base64 string instead of File object
                "metadata": {
                    "idToken": storedToken,
                    "sessionname": getSessionNameFromUrl(),
                    "timestamp": Date.now(),
                }
            };
            ImageAnalysisAPI(requestBody);

        } catch (error) {
            console.error('Error in handleCapture:', error);
        }
    };

    const handleScan = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) {
            console.error('No token available');
            return;
        }

        setScan(!scan);
        
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        
        if (!scan && mounted) { // Only start if component is mounted
            intervalIdRef.current = setInterval(handleCapture, scanFrequency);
            intervalIdRef.current = setInterval(getEmotion, scanFrequency);
        }
    };

    async function getEmotion() {
        const idToken = localStorage.getItem('idToken');
        const requestBody = JSON.stringify({
            "request_type": "user",
            "idToken":  idToken,
            "session_name": getSessionNameFromUrl(),
            "user_request_type": "latest_emotions"
        });
        const data = await UserAPI(requestBody);
        // Check if we have valid data and body
        if (!data || !data.body || data.body === "{}") {
            localStorage.setItem('head_visible', 'false');
            return;
        }
    
        // Parse the outer JSON structure
        const result = JSON.parse(data.body);
        
        if (result && result.emotions) {
            // Find emotion with highest confidence value
            const highestEmotion = Object.entries(result.emotions)
                .filter(([emotion]) => emotion !== 'timestamp')
                .reduce((max, [emotion, confidence]) => 
                    confidence > max[1] ? [emotion, confidence] : max,
                    ['', 0]
                )[0];
            
    
            const head_position = result.head_position;
            const is_nodding = head_position.is_nodding;
            const nodding_frequency = head_position.nodding_frequency;
            const nodding_amplitude = head_position.nodding_amplitude;
    
            localStorage.setItem('emotion', highestEmotion);
            localStorage.setItem('is_nodding', is_nodding);
            localStorage.setItem('nodding_frequency', nodding_frequency);
            localStorage.setItem('nodding_amplitude', nodding_amplitude);
            localStorage.setItem('head_visible', 'true');
        } else {
            localStorage.setItem('head_visible', 'false');
            localStorage.setItem('is_nodding', 'unknown');
            localStorage.setItem('emotion', 'unknown');
            localStorage.setItem('nodding_frequency', 0);
            localStorage.setItem('nodding_amplitude', 0);
        }
    }

//////////////////////////////////// VARIOUS SETTINGS AND MENUS ////////////////////////////////////

const openTutorial = async () => {
    setTutorial(!tutorial);
}


      const openYoutubeChannel = async () => {
        window.open(YT_channel_URL, '_blank');
      };

    const getCredits = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;

        try {
            const bodyData = JSON.stringify({
                'request_type': 'user',
                'idToken': storedToken,
                'user_request_type': 'getcredits'
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData && mounted) {  // Check if component is still mounted
                const result = JSON.parse(jsonData.body);
                if (result && result.credits !== undefined) {  // Validate credits exist
                    setCredits(result.credits);
                }
            }
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    };

    const getPoints = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;

        try {
            const bodyData = JSON.stringify({
                'request_type': 'user',
                'idToken': storedToken,
                'user_request_type': 'getuserstatus',
                'session_name': getSessionNameFromUrl()
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData && mounted && jsonData.body) {
                const result = JSON.parse(jsonData.body);
                if (result && result[1] !== undefined) {
                    setPoints(result[1]);
                }
            }
        } catch (error) {
            console.error('Error fetching points:', error);
        }
    };

    const getLastRequest = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) return;
        
        try {
            const bodyData = JSON.stringify({
                'request_type': 'transaction',
                'transaction_type': 'lasttransaction',
                'idToken': storedToken,
                'session_name': getSessionNameFromUrl()
            });
            const jsonData = await UserAPI(bodyData);
            if (jsonData && mounted && jsonData.body) {
                const result = JSON.parse(jsonData.body);
                if (result && result.artist) {
                    setLastArtist(result.artist.replace("%q", "'"));
                    setLastSongName(result.song_name.replace("%q", "'"));
                }
            }
        } catch (error) {
            console.error('Error fetching last request:', error);
        }
    };

    const getSettings = async () => {
        const bodyData = JSON.stringify({
            'request_type': 'info',
            'info_type': 'getsettings',
            'session_name': getSessionNameFromUrl()
        });
        const jsonData = await InfoAPI(bodyData);
        const result = JSON.parse(jsonData.body);
        const embeddingVar = result.YT_embed_bool;
        const YT_channel_URL = result.YT_channel_URL;
        setTimeDelay(result.timedelay);
        setTimeInterval(result.timeinterval);
        setPointsperwin(result.pointsperwin);
        setPointspersongrequestgrant(result.pointspersongrequestgrant);
        if (embeddingVar==0) {
            setEmbedding(false);
        }
        else {
            setEmbedding(true);
        }
        if (YT_channel_URL) {
            setYT_channel_URL(YT_channel_URL);
        }
    }

    useEffect(() => {
        setMounted(true);

        // Initial fetch
        const fetchInitialData = async () => {
            await getCredits();
            await getPoints();
            await getLastRequest();
            await getSettings();
        };
        fetchInitialData();

        // Set up intervals for polling
        const creditsInterval = setInterval(getCredits, refreshTime);
        const pointsInterval = setInterval(getPoints, refreshTime);
        const lastRequestInterval = setInterval(getLastRequest, refreshTime);
        const settingsInterval = setInterval(getSettings, refreshTime);

        // Cleanup function
        return () => {
            setMounted(false);
            clearInterval(creditsInterval);
            clearInterval(pointsInterval);
            clearInterval(lastRequestInterval);
            clearInterval(settingsInterval);
            // Clear the scan interval if it exists
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
            setScan(false); // Reset scan state
        };
    }, []);
           
    
//////////////////////////////////// LOCAL DETECTION ////////////////////////////////////
    const handleFaceApiReady = () => {
        console.log('Face API is ready for detection');
    };

    const handleFaceApiError = (error) => {
        console.error('Face API error:', error);
    };



    const handleLocalDetection = async () => {
        const storedToken = localStorage.getItem('idToken');
        if (!storedToken) {
            console.error('No token available');
            return;
        }
        setScan(!scan);
        
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }

        if (!scan && mounted) {
            try {
                // Use the states from useModelLoading hook
                if (!faceapi.nets.tinyFaceDetector.isLoaded || 
                    !faceapi.nets.faceLandmark68Net.isLoaded || 
                    !faceapi.nets.faceExpressionNet.isLoaded) {
                    await loadModels();
                }
                
                const emotionArray = [];
                const timeStampArray = [];
                const X_array = [];
                const Y_array = [];
                const width_array = [];
                const height_array = [];
                
                intervalIdRef.current = setInterval(
                    () => runDetection(emotionArray, timeStampArray, X_array, Y_array, width_array, height_array), 
                    scanFrequency
                );
            } catch (error) {
                console.error('Error initializing face detection:', error);
                setScan(false);  // Disable scanning if there's an error
            }
        }
    };

    const runDetection = async (emotionArray, timeStampArray, X_array, Y_array, width_array, height_array) => {
        if (!videoRef.current) return;
        try {
            const detection = await faceapi.detectSingleFace(
                videoRef.current,
                FACE_DETECTOR_OPTIONS
            ).withFaceLandmarks().withFaceExpressions();

            if (detection) {
                const { width, height, top, left } = detection.detection.box;
                
                const results = {
                    timestamp: Date.now(),
                    emotion: detection.expressions.happy > 0.7 ? 'happy' : 
                             detection.expressions.surprised > 0.7 ? 'surprised' :
                             detection.expressions.sad > 0.7 ? 'sad' :
                             detection.expressions.angry > 0.7 ? 'angry' :
                             detection.expressions.fearful > 0.7 ? 'fearful' :
                             detection.expressions.disgusted > 0.7 ? 'disgusted' : 'neutral',
                    headPosition: {
                        width: Math.round(width),
                        height: Math.round(height),
                        position: {
                            top: Math.round(top),
                            left: Math.round(left),
                            centerX: Math.round(left + width/2),
                            centerY: Math.round(top + height/2)
                        }
                    }
                };
                emotionArray.push(results.emotion);
                timeStampArray.push(results.timestamp);
                X_array.push(results.headPosition.position.centerX);
                Y_array.push(results.headPosition.position.centerY);
                width_array.push(results.headPosition.width);
                height_array.push(results.headPosition.height);
                //When arrays are filled, send data to server and clear arrays
                if (emotionArray.length > 9) {
                    const idToken = localStorage.getItem('idToken');
                    const requestBody = JSON.stringify({
                        "request_type": "user",
                        "idToken":  idToken,
                        "session_name": getSessionNameFromUrl(),
                        "user_request_type": "local_detection",
                        "emotion_array": emotionArray,
                        "timestamp_array": timeStampArray,
                        "X_array": X_array,
                        "Y_array": Y_array,
                        "width_array": width_array,
                        "height_array": height_array
                    });
                    const data = await UserAPI(requestBody);
                    const result = JSON.parse(data.body);
                    emotionArray.length = 0;
                    timeStampArray.length = 0;
                    X_array.length = 0;
                    Y_array.length = 0;
                    width_array.length = 0;
                    height_array.length = 0;
                    localStorage.setItem('is_nodding', result.is_nodding);
                    localStorage.setItem('nodding_frequency', result.nodding_frequency);
                    localStorage.setItem('nodding_amplitude', result.nodding_amplitude);
                    localStorage.setItem('head_visible', 'true');
                }
                
                localStorage.setItem('emotion', results.emotion);
            }
            else {
                localStorage.setItem('head_visible', 'false');
                localStorage.setItem('emotion', 'unknown');
                localStorage.setItem('is_nodding', 'unknown');
                localStorage.setItem('nodding_frequency', 0);
                localStorage.setItem('nodding_amplitude', 0);
            }
        } catch (error) {
            console.error('Detection error:', error);
            handleFaceApiError(error);
        }

        // Schedule next detection only if still running
        if (isRunningRef.current) {
            detectionLoopRef.current = setTimeout(runDetection, scanFrequency);
        }
    };
    

      useEffect(() => {
        if (isReady && !detectorInitialized && videoRef.current) {
            try {
                if (videoRef.current.readyState === 4) {
                    setDetectorInitialized(true);
                    handleFaceApiReady();
                } else {
                    videoRef.current.addEventListener('loadeddata', () => {
                        setDetectorInitialized(true);
                        handleFaceApiReady();
                    });
                }
            } catch (error) {
                console.error('Error initializing face detector:', error);
                handleFaceApiError(error);
            }
        }
    
        return () => {
            clearTimeout(detectionLoopRef.current);
        };
      }, [isReady, detectorInitialized]);

    //////////////////////////////////// COMPONENTS RENDERING ////////////////////////////////////

    return (
        <div style={{
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '20px',
            boxSizing: 'border-box',
            background: backgroundColor
        }}>
            {isMobile ? (
                // Mobile Layout
                <>
<ContentRow>
                    <HalfContentContainer style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',  // Stack elements vertically
                    }}>

                    <button onClick={openTutorial}>
                            {tutorial ? 'Close tutorial' : 'Tutorial'}
                        </button>
                        <p>Selected session: {sessionName}</p>
                        
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            {points && <p>Pts: {points}</p>}
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                                <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '1em', height: '1em' }} />
                                <p>{credits !== null ? `${credits}` : 'Loading credits...'}</p>
                            </div>
                        </div>
                        
                        <p>Song requested: {lastSongName !== null ? lastSongName+' by '+lastArtist : 'None'}</p>

                        </HalfContentContainer>
                        <HalfContentContainer style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',  // Stack elements vertically
                    }}>
                        <p>Impressions collected for last {timeDelay !== null ? `${timeDelay}s` : 'Loading time delay...'}</p>
                        <p>Time between two impression collections: {timeInterval !== null ? `${timeInterval}s` : 'Loading time interval...'}</p>
                        <p>Points per win: {pointsperwin !== null ? `${pointsperwin}` : 'Loading points per win...'}</p>
                        <p>Points per song request grant: {pointspersongrequestgrant !== null ? `${pointspersongrequestgrant}` : 'Loading points per song request grant...'}</p>
                    </HalfContentContainer>
                    </ContentRow>

                    {tutorial && (
                            <ContentContainer>
                                <Tutorial/>
                            </ContentContainer>
                        )}
                        
                    <div style={{ 
                        marginLeft: '0',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '0 20px',
                        boxSizing: 'border-box'
                    }}>
                        {embedding && (
                            <ContentContainer style={{ height: '50rem' }}>
                                <EmbeddedStream height={50} sessionName={getSessionNameFromUrl()}/>
                            </ContentContainer>
                        )}

                        <ContentContainer>
                        <button onClick={handleLocalDetection}>
                                                    {scan ? 'Disable Detection' : 'Enable Detection'}
                                                </button>
                                <div id="emotions" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.625rem' }}>
                                    <div id="webcam" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                        {stream ? (
                                            <>
                                                <video
                                                    autoPlay
                                                    playsInline
                                                    muted
                                                    ref={video => {
                                                        if (video && !video.srcObject) {
                                                            video.srcObject = stream;
                                                            videoRef.current = video;
                                                        }
                                                    }}
                                                    type="video/webm,video/mp4,video/ogg"
                                                    style={{ 
                                                        width: '200px',
                                                        height: '150px',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                 {/* <button onClick={handleScan} id="scanButton">{scan ? 'Disable Cloud Detection' : 'Enable Cloud Detection'}</button> */}
                                                
                                            </>
                                        ) : (
                                            <div>Loading camera...</div>
                                        )}
                                    </div>
                                    {
                                    <div>
                                        {(!scan) && (
                                            <p>Start sharing emotions to get points</p>
                                        )}
                                        {(scan) && (      
                                            <>
                                                {headVisible === 'false' && (
                                                    <p>No face recognized...</p>
                                                )}
                                                {headVisible === 'true' && (
                                                    <>
                                                        <p>Smiling ? {currentEmotion === 'happy' ? 'Yes' : 'No'}</p>
                                                        <p>Suprised ? {currentEmotion === 'surprised' ? 'Yes' : 'No'}</p>
                                                        <p>Nodding ? {isNodding ? 'Yes (amp.: ' + Number(noddingAmplitude).toFixed(2)+' @ '+ (Number(noddingFrequency)*60).toFixed(0)+' bpm)' : 'No (amp.: ' + Number(noddingAmplitude).toFixed(2)+ ')'}</p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    }
                                </div>
                                </ContentContainer>

                            <ContentContainer>
                                <Impressions sessionName={sessionName}/>
                            </ContentContainer>

                            <ContentContainer>
                                <UserSongsMenu sessionName={sessionName}/>
                            </ContentContainer>
                    </div>
                </>
            ) : (
                // Desktop Layout
                <div style={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
                    <SecColumn>          
                        
                        <button onClick={openTutorial}>
                            {tutorial ? 'Close tutorial' : 'First time user ? Open tutorial'}
                        </button>
                        {YT_channel_URL !== 'none' && <button onClick={openYoutubeChannel}>Open Youtube channel</button>}
                        <div id="info_tab" style={{ display: 'flex', flexDirection: 'column', gap: '0.625rem', padding: padding }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Selected session: {sessionName}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.625rem' }}>
                                {points && <p>Pts : {points}</p>}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.3125rem' }}>
                                    <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '1em', height: '1em' }} />
                                    <p>{credits !== null ? `${credits}` : 'Loading credits...'}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Impressions collected for last {timeDelay !== null ? `${timeDelay}s` : 'Loading time delay...'}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Time between two impression collections: {timeInterval !== null ? `${timeInterval}s` : 'Loading time interval...'}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Points per win: {pointsperwin !== null ? `${pointsperwin}` : 'Loading points per win...'}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Points per song request grant: {pointspersongrequestgrant !== null ? `${pointspersongrequestgrant}` : 'Loading points per song request grant...'}</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Song requested: {lastSongName !== null ? lastSongName+' by '+lastArtist : 'None'}</p>
                            </div>
                        </div>
                    </SecColumn>

                    <div style={{ 
                        marginLeft: `${navigationWidthNumber + secMenuUserWidthNumber + 4}rem`,
                        width: `calc(100% - ${navigationWidthNumber + secMenuUserWidthNumber + 6}rem)`,
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center' 
                    }}>
                        {embedding && (
                            <ContentContainer style={{ height: '50rem' }}>
                                <EmbeddedStream height={50} sessionName={getSessionNameFromUrl()}/>
                            </ContentContainer>
                        )}

                        {tutorial && (
                            <ContentContainer>
                                <Tutorial/>
                            </ContentContainer>
                        )}

                        <ContentContainer>
                                <div id="emotions" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.625rem' }}>
                                    <div id="webcam" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                        {stream ? (
                                            <>
                                                <video
                                                    autoPlay
                                                    playsInline
                                                    muted
                                                    ref={video => {
                                                        if (video && !video.srcObject) {
                                                            video.srcObject = stream;
                                                            videoRef.current = video;
                                                        }
                                                    }}
                                                    type="video/webm,video/mp4,video/ogg"
                                                    style={{ 
                                                        width: '200px',
                                                        height: '150px',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                {/* <button onClick={handleScan} id="scanButton">{scan ? 'Disable Cloud Detection' : 'Enable Cloud Detection'}</button> */}
                                                <button onClick={handleLocalDetection}>
                                                    {scan ? 'Disable Detection' : 'Enable Detection'}
                                                </button>
                                            </>
                                        ) : (
                                            <div>Loading camera...</div>
                                        )}
                                    </div>
                                    {
                                    <div>
                                        {(!scan) && (
                                            <p>Start sharing emotions to get points</p>
                                        )}
                                        {(scan) && (      
                                            <>
                                                {headVisible === 'false' && (
                                                    <p>No face recognized...</p>
                                                )}
                                                {headVisible === 'true' && (
                                                    <>
                                                        <p>Smiling ? {currentEmotion === 'happy' ? 'Yes' : 'No'}</p>
                                                        <p>Suprised ? {currentEmotion === 'surprised' ? 'Yes' : 'No'}</p>
                                                        <p>Nodding ? {isNodding ? 'Yes (amplitude: ' + Number(noddingAmplitude).toFixed(2)+' @ '+ Number(noddingFrequency*60).toFixed(0)+' bpm)' : 'No (amplitude: ' + Number(noddingAmplitude).toFixed(2)+ ')'}</p>
                                                        
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    }
                                </div>
                                </ContentContainer>
                                <ContentContainer>
                                    <Impressions sessionName={sessionName}/>
                                </ContentContainer>

                        
                            <ContentContainer>
                                <UserSongsMenu sessionName={sessionName}/>
                            </ContentContainer>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SessionUserUI;