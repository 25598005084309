import React, { useEffect, useState } from 'react';
import InfoAPI from '../components/InfoAPI';
import SessionUserUI from '../components/SessionUserUI';
import sessionSelectionImage from '../images/sessionSelection.png';
import sessionDefaultImage from '../images/session_default.png';
import { roundedCornersRadius } from '../utils/DisplaySettings';
import isMobileFunc from '../utils/DisplaySettings';
import { navigationWidthNumber } from '../utils/DisplaySettings';
import { useNavigate } from 'react-router-dom';

// Navigation in open sessions

async function fetchSessionList(setSessionButtons, handleSessionButtonClick) {
  const requestBody = JSON.stringify({
    request_type: "info",
    info_type: "active_sessions"
  });

  const data = await InfoAPI(requestBody);
  const sessionsListParsed = JSON.parse(data.body);
  const sessionButtons = sessionsListParsed.map((session, index) => {
    // Calculate font size based on text length
    const baseFontSize = 3; // 3rem default
    const textLength = session.length;
    const fontSize = Math.min(baseFontSize, (15 * baseFontSize) / textLength); // 15 chars fit well with 3rem

    return (
      <div key={index} style={{ width: '30rem', minWidth: '30rem' }}>
        <button 
          style={{ 
            width: '100%',
            minWidth: '100%',
            height: '15rem', 
            borderRadius: roundedCornersRadius,
            backgroundImage: `url(${sessionDefaultImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            fontSize: `${fontSize}rem`,
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            lineHeight: '15rem',
            textShadow: '0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.5)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            padding: '0 1rem',
            boxSizing: 'border-box'
          }}
          onClick={() => handleSessionButtonClick(session)}
        >
          {session}
        </button>
      </div>
    );
  });
  setSessionButtons(sessionButtons);
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

const Attend = () => {
  const [sessionButtons, setSessionButtons] = useState([]);
  const navigate = useNavigate();
  const isMobile = isMobileFunc();

  const handleSessionButtonClick = (session) => {
    localStorage.setItem('selectedSession', session);
    navigate(`/session/${encodeURIComponent(session)}`);
  };

  useEffect(() => {
    let mounted = true;

    const fetchSessions = async () => {
      if (mounted) {
        await fetchSessionList(setSessionButtons, handleSessionButtonClick);
      }
    };

    fetchSessions();

    // Cleanup function
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div style={{
      marginTop: isMobile ? '6rem' : '5vh',
      marginLeft: isMobile ? '0' : `${navigationWidthNumber + 2}rem`,
      width: isMobile ? '100%' : `calc(100% - ${navigationWidthNumber + 2}rem)`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 1.25rem',
      boxSizing: 'border-box'
    }}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <img 
          src={sessionSelectionImage} 
          alt="Session Selection" 
          style={{ 
            maxWidth: '100%',
            height: 'auto',
            marginBottom: '1.25rem',
            borderRadius: '0.5rem'
          }}
        />
      </div>
      <div id="sessionsList" style={{ 
        display: 'flex', 
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1.25rem',
        width: '100%'
      }}>
        {sessionButtons}
      </div>
    </div>
  );
};

export default Attend;