import './index.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import EventOverlay from './pages/EventOverlay.jsx';
import { AuthProvider } from './contexts/AuthContext';
import AuthError from './components/AuthError';

import Home from './pages/Home.jsx';
import Callback from './pages/Callback.jsx';
import Attend from './pages/Attend.jsx';
import Create from './pages/Create.jsx';
import Profile from './pages/Profile.jsx';
import SessionUserPage from './pages/SessionUserPage.jsx';

const App = () => {
  useEffect(() => {
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/attend" element={<Attend />} />
          <Route path="/create" element={<Create />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/overlay/:eventId" element={<EventOverlay />} />
          <Route path="/session/:sessionName" element={<SessionUserPage />} />
        </Routes>
      </Router>
      <AuthError />
    </AuthProvider>
  );
}

export default App;
