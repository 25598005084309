import React from 'react';
import login from '../images/login.png';
import EnvironmentVariables from '../utils/EnvironmentVariables';
import isMobileFunc from '../utils/DisplaySettings';
import { navigationWidthNumber } from '../utils/DisplaySettings';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const isMobile = isMobileFunc();
    const { idToken, handleLogout } = useAuth();
    const navigate = useNavigate();

    const onLogout = () => {
        handleLogout();
        navigate('/');
    };

    return (
        <div style={{
            marginTop: isMobile ? '6rem' : '5vh',
            marginLeft: isMobile ? '0' : `${navigationWidthNumber + 2}rem`,
            width: isMobile ? '100%' : `calc(100% - ${navigationWidthNumber + 2}rem)`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0 20px',
            boxSizing: 'border-box'
        }}>
            <img 
                src={login} 
                style={{
                    maxWidth: '100%',
                    height: 'auto'
                }}
            />

            {!code && (
                <div style={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {idToken ? (
                        <button onClick={onLogout}>
                            Logout
                        </button>
                    ) : (
                        <button onClick={() => window.location.href = EnvironmentVariables.AuthURL}>
                            Sign up/login
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default Home;