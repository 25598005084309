import React from 'react';

const Tutorial = () => {
    return (
        <div>
            <h1>Tutorial</h1>
            <p>If you donate credits to the streamer and request a song, you have a chance to appear on the leaderboard and have your requested song played.</p>
            <p>One credit is equal to 0.01$. In this test version, you are given 500 credits.</p>
            <p>There are two ways to gain points :</p>
            <p>1. By sharing your emotions with the camera (1 points per 3 seconds where your face is recognized).</p>
            <p>2. By sharing your impressions of the song using the cursors. Regularly, the average over users impressions is computed from the last period. The user closest to the average gets additional points.</p>
            <p>Viewers with the most points appear on the leaderboard.</p>
            <p><strong>Good luck and have fun!</strong></p>

        </div>
    );
};

export default Tutorial;
