import React, { useEffect } from 'react';
import UserAPI from './UserAPI';
import { formatTitleToDB, formatTitleToDisplay } from '../utils/Functions';
import isMobileFunc from '../utils/DisplaySettings';
import { useState } from 'react';
import TuneCoinImage from '../images/tunecoin.png';
import { StyledNumberInput } from '../utils/StyledComponents';

const UserSongsMenu = ({ sessionName }) => {
    const [songs, setSongs] = React.useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const loadSongs = async()    => {
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': localStorage.getItem('idToken'),
            'user_request_type': 'songs',
            'action': 'getSessionSongList',
            'session_name': sessionName
        });

    const jsonData=await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);
    setSongs([]);
    result.forEach(element => {
        var title = element[0];
        title= formatTitleToDisplay(title);
        var artist = element[1];
        artist=formatTitleToDisplay(artist);
        setSongs(prevSongs => [...prevSongs, {title: title, artist: artist}]);
    });

};
    
    const handleRequest = async (index) => {
        const credits = document.getElementById('creditsInput').value;
        const title = songs[index].title;
        const artist = songs[index].artist;
        const formattedTitle = formatTitleToDB(title);
        const formattedArtist = formatTitleToDB(artist);
        const requestBody = JSON.stringify({
            'request_type': 'transaction',
            'idToken': localStorage.getItem('idToken'),
            'transaction_type': 'songrequest',
            'recipient': sessionName,
            'song_name': formattedTitle,
            'artist': formattedArtist,
            'credits': parseInt(credits, 10)
        });
        console.log(requestBody);
        await UserAPI(requestBody);
        setConfirmationMessage("Requested!");
        setTimeout(() => {
            setConfirmationMessage('');
        }, 2000);
    }
    
    

    useEffect (() => {
       loadSongs();
    },[]);
    return (
        <div style={{ 
            width: '100%',
            maxWidth: '100%',
            overflowX: 'hidden',
            padding: '0 0.625rem',
            boxSizing: 'border-box'
        }}>
                                    <div style={{display: 'inline-block', marginRight: '0.5rem'}}>Enter credits to request a song</div>
                                    
                                    
                                    <StyledNumberInput 
                            id={`creditsInput`}
                            min={1}
                            placeholder="Credits"
                            width="3.75rem"
                            margin="0 0 1rem 0"

                        /> <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '1em', height: '1em' }} />

            <div id='confirmationmessage'>{confirmationMessage}</div>
            <div style={{ 
                display: 'flex',  
                fontWeight: 'bold', 
                fontSize: '1.2em', 
                marginBottom: '0.625rem'
            }}>
                <div style={{ width: '35%' }}>Title</div>
                <div style={{ width: '35%' }}>Artist</div>
                <div style={{ width: '30%' }}></div>
            </div>
            <hr />
            {songs.map((song, index) => (
                <div key={index} style={{ 
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.625rem',
                    width: '100%'
                }}>
                    <div style={{ 
                        width: '35%', 
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingRight: '0.625rem'
                    }}>
                        {song.title}
                    </div>
                    <div style={{ 
                        width: '35%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingRight: '0.625rem'
                    }}>
                        {song.artist}
                    </div>
                    <div style={{ 
                        width: '30%', 
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '0.625rem'
                    }}>

                        <button 
                            onClick={() => handleRequest(index)}
                            style={{
                                whiteSpace: 'nowrap',
                                minWidth: 'fit-content'
                            }}
                        >
                            Request
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UserSongsMenu;