import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

const AuthContext = createContext({
    idToken: null,
    accessToken: null,
    setIdToken: () => {},
    setAccessToken: () => {}
});

function getTokensFromUrl() {
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  return {
    idToken: hashParams.get('id_token'),
    accessToken: hashParams.get('access_token')
  };
}

export const AuthProvider = ({ children }) => {
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      const { idToken, accessToken } = getTokensFromUrl();
      if (idToken && accessToken) {
        setIdToken(idToken);
        setAccessToken(accessToken);
        // Store tokens in localStorage
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
      } else {
        // If tokens are not in URL, try to get them from localStorage or current session
        const storedIdToken = localStorage.getItem('idToken');
        const storedAccessToken = localStorage.getItem('accessToken');
        if (storedIdToken && storedAccessToken) {
          setIdToken(storedIdToken);
          setAccessToken(storedAccessToken);
        } else {
          const session = await fetchAuthSession();
          if (session && session.tokens) {
            setIdToken(session.tokens.idToken);
            setAccessToken(session.tokens.accessToken);
            localStorage.setItem('idToken', session.tokens.idToken);
            localStorage.setItem('accessToken', session.tokens.accessToken);
          }
        }
      }
    } catch (error) {
      console.error('Error checking auth state:', error);
    }
  };

  const value = {
    idToken,
    accessToken,
    setIdToken,
    setAccessToken
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};