import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import UserAPI from './UserAPI';
import InfoAPI from './InfoAPI';
import { StyledTextInput, StyledNumberInput } from '../utils/StyledComponents';

// Reduced sizes while maintaining proportions
const inputWidth = 'clamp(15rem, 40vw, 50rem)';  // min: 15rem, preferred: 40% of viewport, max: 30rem
const buttonWidth = 'clamp(8rem, 15vw, 15rem)';  // min: 8rem, preferred: 15% of viewport, max: 15rem

const SettingsMenuCreate = () => {
    const { idToken } = useAuth();
    const [timeDelay, setTimeDelay] = useState(null);
    const [timeInterval, setTimeInterval] = useState(null);
    const [embedding, setEmbedding] = useState(null);
    const [embeddingURL, setEmbeddingURL] = useState(null);

    const changeSetting = async (setting) => {
        const fetchedValue = document.getElementById(setting + 'input')?.value || '';
        if (fetchedValue === '') {
            return;
        }
        const requestBody = JSON.stringify({
            'request_type': 'user',
            'idToken': idToken,
            'user_request_type': 'changesetting',
            'setting': setting,
            'value': fetchedValue
        });
        await UserAPI(requestBody);
        const jsonData = await UserAPI(JSON.stringify({'request_type':'user', 'idToken': idToken, 'user_request_type': 'getsettings' }));
        const result = JSON.parse(jsonData.body);
        setTimeDelay(result.timedelay);
        setTimeInterval(result.timeinterval);
        setEmbedding(result.embed_bool);
        setEmbeddingURL(result.embed_url);
    };

    const getEmbedding = async () => {
        const sessionName = idToken ? JSON.parse(atob(idToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')))['cognito:username'] : '';
        const bodyData = JSON.stringify({
            'request_type': 'info',
            'info_type': 'embedding',
            'session_name': sessionName
        });
        const jsonData = await InfoAPI(bodyData);
        const result = JSON.parse(jsonData.body);
        return result.YT_embed_bool;
    }

    const startstopEmbedding = async () => {
        const embeddingBool = await getEmbedding();
        if (!embeddingBool) {
            var embedURL = document.getElementById('embedURLinput')?.value || '';
            
            const youtubeEmbedRegex = /^https:\/\/www\.youtube\.com\/embed\/[\w-]+\?si=[\w-]+$/;
            if (!youtubeEmbedRegex.test(embedURL)) {
                alert('Please enter a valid YouTube embed URL in the format: https://www.youtube.com/embed/XXXXXXXXXXX?si=YYYYYYYYYYYYYYYY');
                return;
            }
       
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': localStorage.getItem('idToken'),
                'user_request_type': 'startembedding',
                'url': embedURL
            });
            await UserAPI(requestBody);
            setEmbedding(true);
            setEmbeddingURL(embedURL);
        }
        else {
            const requestBody = JSON.stringify({
                'request_type': 'user',
                'idToken': localStorage.getItem('idToken'),
                'user_request_type': 'stopembedding'
            });
            await UserAPI(requestBody);
            setEmbedding(false);
            setEmbeddingURL(null);
        }
    };

    const getSettings = async () => {
        try {
            if (!idToken) {
                console.error('No token available');
                return;
            }

            console.log('Getting settings');
            const jsonData = await UserAPI(JSON.stringify({ 
                'request_type': 'user', 
                'idToken': idToken,
                'user_request_type': 'getsettings' 
            }));
            
            if (!jsonData) return;
            
            const result = JSON.parse(jsonData.body);
            setTimeDelay(result.timedelay);
            setTimeInterval(result.timeinterval);
            setEmbedding(result.YT_embed_bool);
            setEmbeddingURL(result.YT_embed_URL);
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    useEffect(() => {
        getSettings();
    }, [idToken]);
    
    return (
        <div>

            <div>
                
                <StyledNumberInput 
                    id="timedelayinput" 
                    width={inputWidth}
                    placeholder={`Enter (current value: ${timeDelay}s)`}
                />
                <button 
                    style={{ width: buttonWidth }}
                    onClick={() => changeSetting("timedelay")}
                >
                    Change Time Delay
                </button>
                <p>
                    Last period of time during which impressions are collected.
                </p>
            </div>
            <div >
                
                <StyledNumberInput 
                    id="timeintervalinput" 
                    width={inputWidth}
                    placeholder={`Enter (current value: ${timeInterval}s)`}
                />

                <button 
                    style={{ width: buttonWidth }}
                    onClick={() => changeSetting("timeinterval")}
                >
                    Change Time Interval
                </button>
                <p>
                    Time between two impression collections.
                </p>
            </div>

            <div ></div>
            
            <StyledTextInput 
                id="embedURLinput" 
                width={inputWidth}
                placeholder={`Enter ${embeddingURL ? `(current: ${embeddingURL})` : 'URL: https://www.youtube.com/embed/XXXXXXXXXXX?si=YYYYYYYYYYYYYYYY'}`}
            />

        <button 
            style={{ width: buttonWidth }}
            onClick={startstopEmbedding} 
            id="embeddingButton"
        >
              {embedding ? 'Stop embedding' : 'Start embedding'}
            </button>
            <p>
                In the video youtube page, click on the share button, click on embed, and copy the embed URL.
            </p>
        </div>
    );
};

export default SettingsMenuCreate;
